import React from "react"
import { Link } from "gatsby"
import Squeeze from "../layouts/squeeze-landing"
import Hero from "../components/hero"
import Base from "../layouts/base"
import SEO from "../components/SEO"
import Specs from "../components/specs"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import WhereToBuy from "../components/whereToBuy"
import TextLeftImgRight from "../components/textLeftImgRight"
import ImgLeftTextRight from "../components/imgLeftTextRight"

export default () => (
  <div>
    <SEO
      title="Limitless CeramicSteel Whiteboard | Magnetic Dry Erase Board"
      description="This is the description"
    />
    <Base>
      <Hero
        name="Limitless"
        headline="Simple Frameless Standoff Mounting"
        text="Add a modern yet functional writing surface to any space. Featuring standoff mounting hardware, a frameless floating edge design, and our Lifetime Warranty, Limitless is designed to fit wherever you need it."
        image="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-full.jpg"
        color="rgb(128, 228, 114)"
      />
      <Squeeze>
        <TextLeftImgRight
          heading="The World's Most Durable Whiteboard"
          p1="The Panls Limitless surface combines the best qualities of porcelain
					and steel to create our incredibly rugged CeramicSteel dry erase surface."
          p2="The result of this process is a smooth, high-contrast dry erase surface able to resist
					virtually all types of damage. You can run these whiteboards over, drop them, deface
					them with permanent markers or paint, burn them, scrub them, or even pour acid on them without
					any damage."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-durability.png"
          buybtn="d-none"
        />
        <ImgLeftTextRight
          heading="Easy Installation"
          p1="Limitless includes our simple standoff mounting system, detailed installation instructions,
					and a mounting template for stress-free hanging on your wall."
          p2="Once mounts are in place, simply slide Limitless over the mounting studs and secure with the
					included caps, finished in appealing satin chrome.	With this system, Limitless can be
					installed in only three steps and will appear to be floating off the wall."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-install-kit.jpg"
        />
        <TextLeftImgRight
          heading="Stress-Free Cleanup"
          p1="Unlike other dry erase boards, Limitless can return to a factory-fresh look with just a
					simple wipe thanks to CeramicSteel. With the proper cleaning, you will never see any
					ghosting from old notes that were not completely erased."
          p2="For basic
					daily cleanings, we recommend wiping Limitless with a clean
					cloth and warm water or whiteboard cleaner.
					You can also clean Limitless with scrubbing brushes and a wide range of
					household glass cleaners and detergents.
					You can even use strong caustic or acid-based
					solutions without damaging the dry erase surface."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-cleaning.jpg"
          buybtn="d-none"
        />
        <div className="row mb-4 d-flex text-center">
          <div className="six columns py-2 px-2 bg-light-grey border">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/magnet.png"
              className="mb-1"
            />
            <h2 className="mb-1">Magnetic from Edge to Edge</h2>
            <p>
              All Panls whiteboards, including Limitless, are fully magnetic
              from edge to edge right out of the box thanks to the CeramicSteel
              dry erase surface.
            </p>
          </div>
          <div className="six columns py-2 px-2 bg-light-grey border">
            <img
              src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/focused-eye.png"
              className="mb-1"
            />
            <h2 className="mb-1">Design Focused</h2>
            <p>
              Our goal from day one was to create a durable dry erase board with
              a clean aesthetic that was also highly functional. Limitless is
              the result of our design process - a perfect fit as a school
              whiteboard or business whiteboard.
            </p>
          </div>
        </div>
        <div className="row mb-4 text-center">
          <h2 className="mb-1">Find the Perfect Fit for Your Space</h2>
          <p>The Panls Limitless is available in three sizes 4'x4', 4'x6' and 4'x8'.</p>
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-size-guide.png"
            className="img-fluid"
          />
        </div>
        <TextLeftImgRight
          heading="Sharp Contrast, Crisp Detail"
          p1="The Panls CeramicSteel dry erase surface is similar to glassboard surfaces in terms of the clean aesthetic,
					yet it offers much better visual clarity."
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-hero.jpg"
        />
        <ImgLeftTextRight
          heading="Forever Warranty"
          p1="Limitless comes standard with our Lifetime Warranty –
					that’s not a baseless claim, it’s a truly unlimited warranty
					for as long as you own the whiteboard. Our whiteboards are designed
					to last at least 50 years, but we’ll support you with warranty
					service for as long as you need. Your dry erase board is still
					covered even if it lasts 100 years!"
          img="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-blank.png"
          buybtn="d-none"
        />
        <WhereToBuy />
        <div className="bg-light-grey">
          <h1 className="mt-3 mb-1 px-1 py-1">Tech Specs</h1>
          <hr />
          <Specs
            attribute1="Available Sizes"
            spec1="4x4ft • 4x6ft • 4x8ft"
            attribute2="Surface Type"
            spec2="CeramicSteel"
          />
          <Specs
            attribute1="Weight"
            spec1="32lbs • 50lbs • 66lbs"
            attribute2="Warranty"
            spec2="Lifetime"
          />
        </div>
        <div className="row my-3 px-1">
          <h1>CeramicSteel Whiteboard vs Glassboard</h1>

          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/limitless-vs-glass.jpg"
            className="img-fluid my-2"
          />
        </div>
        <div className="row px-1 d-flex-center">
          <div className="six columns py-2">
            <h2 className="mb-2 div-heading">The Panls Advantage</h2>
            <p>
              If you ask us, we’ll say CeramicSteel is the best dry erase material
              on the market. We’re not just saying that – we actually believe it’s
              true. CeramicSteel easily beats painted steel and melamine in terms
              of overall durability and resistance to scratching, and compares
              very strongly with glass dry erase boards. Glassboards are great to
              look at but they lack durability, crisp visual contrast, and an easy
              installation process. With Limitless, our goal was to make a
              whiteboard that looked just as good as glass with none of the
              drawbacks.
            </p>
          </div>
          <div className="six columns py-2">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe className="embed-responsive-item" src="https://www.youtube.com/embed/5vfoO7UvJSM" allowfullscreen></iframe>
            </div>
          </div>
        </div>
        <div className="row my-3 px-1">
          <ul>
            <li className="my-2">
              <strong>Excellent Visibility & Contrast</strong> - CeramicSteel is
              a smooth, non-porous surface just like you get on a glassboard.
              The big difference is the level of contrast between dry erase ink
              and writing surface. Glass is a transparent material – this
              significantly lowers the level of contrast. Any writing on a glass
              whiteboard will often appear blurred or fuzzy from a distance
              because of this. The CeramicSteel surface provides sharp contrast
              between marker ink and dry erase surface, regardless of the
              whiteboard’s surface color.
            </li>
            <li className="my-2">
              <strong>Glare &amp; Reflection</strong> - Reflections and glare
              are some of the biggest frustrations for presenters using dry
              erase boards because they obscure information from the audience. A
              glassboard really has two reflective panes, one each on the front
              and back, meaning that any light hitting the board will reflect
              off both surfaces and make it hard to see any writing or projected
              images. The CeramicSteel is available in multiple finishes from
              high gloss to matte, all of which are less reflective than glass
              whiteboards.
            </li>
            <li className="my-2">
              <strong>Magnetic Properties</strong> - The CeramicSteel dry erase
              surface is essentially a piece of high-quality steel with a
              porcelain enamel surface fused to the front. This material has
              strong magnetic properties by nature – no additional backings or
              special magnets are required. A glassboard has no magnetic
              properties as standard because glass isn’t a magnetic material!
              Making a glass whiteboard magnetic requires additional backing
              materials which come at an added cost.
            </li>
            <li className="my-2">
              <strong>Shipping Cost</strong> - Panls CeramicSteel dry erase
              boards are durable - we’ve been over that already. Other than
              lasting for decades, this also means our whiteboards are easier to
              ship since they can stand up to the impacts and abuse involved in
              freight shipping. Glassboards actually require special shipping
              practices since they're much more fragile - they must be shipped
              standing straight up to prevent damage, typically at an additional
              cost to the customer. Panls whiteboards can be shipped in any
              orientation with no damage.
            </li>
            <li className="my-2">
              <strong>Installation</strong> - All Panls whiteboards including
              Limitless are extremely lightweight, making for easy installation.
              Each whiteboard comes with detailed mounting instructions which
              make it easy to get your board on the wall quickly with no
              frustration. The included standoff mount system on Limitless has
              only three steps to mounting and only basic tools are required.
              Glassboards are often much heavier than our whiteboards and
              require much more robust mounting hardware. At least three people
              are needed to safely hang a glassboard.
            </li>
            <li className="my-2">
              <strong>Safety</strong> - CeramicSteel won’t crack, split, or
              shatter when dropped, meaning you don’t need to worry about Panls
              whiteboards hitting the ground. With glass whiteboards, even a
              minor drop onto a hard surface can cause serious damage. In the
              worst case, a glassboard could shatter, producing thousands of
              tiny glass shards which are dangerous and difficult to clean up.
            </li>
          </ul>
        </div>

        <div className="row">
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/polyvision_hedwig_standoff_mount_education_v04.jpg"
            className="img-fluid"
          />
        </div>

        <div className="row px-1">
          <h1 className="my-2">Limitless Whiteboard Applications</h1>
          <p>
            Limitless packs a ton of value into a lightweight package that’s
            incredibly durable thanks to CeramicSteel. Limitless is also highly
            magnetic from edge-to-edge so it won’t disrupt your flow when
            switching from a traditional dry erase board. It’s simple to install
            and includes detailed instructions so you’ll be ready to collaborate
            in no time. The easy-to-clean Limitless CeramicSteel surface far
            exceeds other whiteboard materials, giving you stress-free use for
            years to come!
          </p>
        </div>

        <div className="row my-3">
          <div className="four columns">
            <ul>
              <li className="">
                <p className="bold">Interior Design</p>
              </li>
              <li className="">
                <p className="bold">Architecture</p>
              </li>
              <li className="">
                <p className="bold">Hospitality</p>
              </li>
              <li className="">
                <p className="bold">Oil and Gas</p>
              </li>
            </ul>
          </div>
          <div className="four columns">
            <ul>
              <li className="">
                <p className="bold">Technology</p>
              </li>
              <li className="">
                <p className="bold">Construction</p>
              </li>
              <li className="">
                <p className="bold">Healthcare</p>
              </li>
              <li className="">
                <p className="bold">Education</p>
              </li>
            </ul>
          </div>
          <div className="four columns">
            <ul>
              <li className="">
                <p className="bold">Sports</p>
              </li>
              <li className="">
                <p className="bold">Finance</p>
              </li>
              <li className="">
                <p className="bold">Military</p>
              </li>
              <li className="">
                <p className="bold">Government</p>
              </li>
            </ul>
          </div>
        </div>
        <WhereToBuy />
      </Squeeze>
    </Base>
  </div>
)
